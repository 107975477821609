import React from 'react'
import { useOutletContext } from 'react-router-dom'
import PrevNext from '../components/PrevNext'

const AddG = () => {
  const {menu, isMenu} = useOutletContext()
  return (
    <div className='intro' onClick={()=>{
      if(menu){
        isMenu(false)}
      }
    }>
      <article>
        <h3>Wallet grouping</h3>
        <p>
        wallet grouping feature allows you to categorize and organize your Solana wallets into custom groups based on win rates. This makes it easier to manage and track your entire portfolio efficiently.
        </p>
        <img alt='add' src='/images/add4.jpg'/>
        <p>
        You can group wallets with abbreviations A (Alpha), B (Beta), D (Delta), and G (Gamma), based on their win rate.
        </p>
      </article>
      <article>
        <h3>Invalid wallet</h3>
        <p>
        If you encounter this message, it indicates that you may have made an error while adding your wallet, name, or group.Please double-check your input and try again.
        </p>
        <img alt='add' src='/images/add8.jpg'/>
        <p>
        Follow these instructions carefully :
        </p>
        <ul>
            <li>A: Ensure you're entering a valid wallet address</li>
            <li>B: Double-check for any spaces between the wallet address, nickname, and group.</li>
            <li>C: Verify that the group is assigned one of the following abbreviations: A, B, D, or G.</li>
        </ul>
      </article>
      <article>
        <h3>Invalid grouping</h3>
        <p>
        If you encounter this message, it indicates that you may have selected the incorrect group. Please double-check your input and try again.
        </p>
        <img alt='add' src='/images/add5.jpg'/>
        <p>
         Make sure it is grouped with abbreviations A, B ,D, G
        </p>
      </article>
      <article>
        <h3>Existing wallet</h3>
        <p>
        If you encounter this message, it indicates that the wallet address you entered already exists in your database.
        </p>
        <img alt='add' src='/images/add6.jpg'/>
      </article>
      <article>
        <h3>Saved wallet</h3>
        <p>
        This section includes a list of all your wallets , active wallets and inactive wallets 
        </p>
        <img alt='add' src='/images/add7.jpg'/>
      </article>
      <article>
        <p>
        1 All wallets: A list of all your saved wallets 
        </p>
        <p>
        2 Active wallets : A list of wallets sending notifications depending on your subscription 
        </p>
        <p>
        3 Inactive wallets : a list of wallets not sending notifications due to expired subscription.
        All wallets will be grouped accordingly to how you saved them.
        </p>
        <img alt='add' src='/images/add9.jpg'/>
      </article>
      <PrevNext prev='/docs/add-multiple-wallet' next='/docs/deleting-wallet'/>
    </div>
  )
}

export default AddG
