import React from 'react'
import Faq from '../components/Faq';
import Features from '../components/Features';
import Footer from '../components/footer';
import Hero from '../components/Hero';
import Nav from '../components/Nav';
import Pricing from '../components/Pricing';
import Use from '../components/Use';
import Lounge from '../Lounge';

const Home = () => {
  return (
    <div className='App'>
        <Nav/>
        <Hero/>
        <Features/>
        {/* <CommLounge/> */}
        <Use/>
        <Pricing/>
        <Lounge/>
        <Faq/>
        <Footer/>
    </div>
  )
}

export default Home
