import React from 'react'
import { useOutletContext } from 'react-router-dom'
import PrevNext from '../components/PrevNext'

const Acct = () => {
  const {menu, isMenu} = useOutletContext()
  return (
    <div className='intro' onClick={()=>{
      if(menu){
        isMenu(false)}
      }
    }>
      <article>
        <h3>2.5 My account</h3>
        <p>Click /Start then select “my account”</p>
        <img alt='add' src='/images/acc2.jpg'/>
        <p>
        Your account section is your personal dashboard where you can:
        </p>
        <ul>
            <li>View your wallet address</li>
            <li>Check your balance</li>
            <li>View private key</li>
            <li>Deposit funds: To use our services or upgrade your plan, you'll need to deposit funds to your wallet address.</li>
        </ul>
        <img alt='add' src='/images/acc1.jpg'/>
      </article>
      <PrevNext prev='/docs/deleting-wallet' next='/docs/upgrade'/>
    </div>
  )
}

export default Acct
