import React from 'react'
import { useOutletContext } from 'react-router-dom'
import PrevNext from '../components/PrevNext'

const Func = () => {
  const {menu, isMenu} = useOutletContext()
  return (
    <div className='intro' onClick={()=>{
      if(menu){
        isMenu(false)}
      }
    }>
      <article>
        <h3>2.1 How to add wallets</h3>
        <p>
        <strong> 2.1.1 Method 1 :</strong> Open the godeye bot, click on menu and select “/add” </p>
        <img alt='add' src='/images/add1.jpg'/>
        <p>
        <p>send your wallet details in this following format : Wallet name Group</p>
        <img alt='add' src='/images/add2.jpg'/>
        <p>
        As shown in the image, send a single wallet address, followed by a space, the desired nickname for the wallet, and another space. Finally, specify the group you want to place the wallet in based on your assessment. Use "A" for alpha wallets and "B" for beta wallets , D for delta ,G for gamma.
        </p>
        <strong> 2.1.2 Method 2 :</strong>  click /start , click ⚡️Add
        </p>
        <img alt='add' src='/images/add.jpg'/>
      </article>
      <PrevNext prev='/docs/' next='/docs/add-multiple-wallet'/> 
    </div>
  )
}

export default Func
