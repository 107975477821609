import React from 'react'
import { useOutletContext } from 'react-router-dom'
import PrevNext from '../components/PrevNext'

const Tips = () => {
  const {menu, isMenu} = useOutletContext()
  return (
    <div className='intro' onClick={()=>{
      if(menu){
        isMenu(false)}
      }
    }>
    <article>
    <h3>4.0 Tips</h3>
      <p>
        1 To receive notifications for all your wallets and access weekly alpha wallets, please upgrade your plan.<br/><br/>2 We would never ask for your private key via phone, email, or text. Protect yourself from scams.<br/><br/>3 Your referral bonus will be credited to your Godeye account automatically upon successful referrals.<br/><br/>4 We would never ask for your transfer key via phone, email, or text. Protect yourself from scams because anyone who has this key can access your wallet
      </p>
      </article>
      <PrevNext prev='/docs/others' next='/docs/'/>
    </div>
  )
}

export default Tips
