import React from 'react'
import { useOutletContext } from 'react-router-dom'
import PrevNext from '../components/PrevNext'

const Nfts = () => {
  const {menu, isMenu} = useOutletContext()
  return (
    <div className='intro' onClick={()=>{
      if(menu){
        isMenu(false)}
      }
    }>
    <article>
        <h3>3.6 Nft mint</h3>
        <img style={{width: '60%'}} alt='add' src='/images/nmint.jpg' />
      </article>
    <article>
        <h3>3.7 Nft sale</h3>
        <img style={{width: '60%'}} alt='add' src='/images/nsell.jpg'/>
      </article>
    <article>
        <h3>3.8 Nft listing</h3>
        <img style={{width: '60%'}} alt='add' src='/images/nlist.jpg'/>
      </article>
    <article>
        <h3>3.9 Nft cancel listing</h3>
        <img style={{width: '60%'}} alt='add' src='/images/nclist.jpg'/>
      </article>
      <PrevNext prev='/docs/transfers' next='/docs/liquidity'/>
    </div>
  )
}

export default Nfts
