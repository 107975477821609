import React from 'react'

const PrevNext = ({prev, next}) => {
  return (
    <div className='prevNext'>
      <a href={prev}><img alt='left' src='/images/left.png' />Prev</a>
      <a href={next}>Next<img alt='right' src='/images/right.png'/></a>
    </div>
  )
}

export default PrevNext
