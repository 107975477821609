import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const Faq = () => {
  return (
    <div className='faq' id='faq'>
      <section>
        <header><h1>Frequently asked questions - FAQ</h1></header>
        <Accordion className='accordion'>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How do I set up the bot and add my wallets?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Check out our quick tutorial here: <a href='https://www.youtube.com/@Godeye_bots' style={{textDecoration:'none', color: '#04283a'}}>YOUTUBE</a>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What kind of market insights can I expect from the bot?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Our social media channels and other communication platforms keep you informed about the latest developments in the Solana wallet tracking ecosystem.<br/><br/>
                    <b>Token Performance</b>: Track the performance of your favorite tokens with our advanced features.<br/><b>Whale Activity</b>: Monitor the movements of large holders and group them accordingly based on win rate.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What should I do if I encounter a problem with the bot?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    If you encounter any issues with Godeye, here are the steps you can take:<br/><br/>
 1 <b>Check our FAQ</b>: Our FAQ section often provides answers to common problems.<br/>
 2 <b>Contact support</b>: If you can't find the answer you need, reach out to our support team. We're available to assist you with any issues you may encounter. We're committed to providing excellent customer support and resolving any problems you may face.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    how does the pricing benefit me?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Our pricing structure offers exceptional value, allowing you to save more while getting more features. Here's why Godeye is the best choice:<br/><br/> • <b>Comprehensive features</b>: Enjoy a wide range of features, including lightening fast alerts, higher wallet limit, in-depth insights,and access to alpha wallets.<br/> • <b>Affordable pricing</b>: Our plans are designed to fit your budget, offering excellent value for money. <br/> • <b>Long-term savings</b>: By using Godeye, you can potentially save significant amounts on missed opportunities or poor investment decisions.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    how many chains do you support?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Godeye is currently optimized for the Solana blockchain. We're committed to expanding our capabilities and may support additional chains in the future.
Stay tuned for updates! Follow us on <a href='https://x.com/godeye_network' style={{color: '#04283a'}}>X/Twitter</a> to be the first to know about new features and chain integrations.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
      </section>
    </div>
  )
}

export default Faq
