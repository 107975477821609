import React, { useEffect, useState } from 'react'
import './comp.css'
const Nav = () => {
  const [mobile, setMobile] = useState(false)
  const media = window.matchMedia('(max-width:620px)').matches
  useEffect(()=>{
setMobile(true)
  }, [media])
  return (
    <div className='nav'>
      <nav>
      <a href='/'><img alt='logo' src='/images/logo.png' /></a>
        <ul>
            <li className='navLink'><a href='#home'>Home</a></li>
            <li className='navLink'><a href='#features'>Features</a></li>
            <li className='navLink'><a href='#pricing'>Pricing</a></li>
            <li className='navLink'><a href='#faq'>FAQs</a></li>
            <li>  <a href='/docs'> <button>
            {mobile ? 'Read Docs' : 'Start Godeye '}
        </button></a></li>
        </ul>
      </nav>
    </div>
  )
}

export default Nav
