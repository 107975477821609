import React from 'react'
import { useOutletContext } from 'react-router-dom'
import PrevNext from '../components/PrevNext'

const Others = () => {
  const {menu, isMenu} = useOutletContext()
  return (
    <div className='intro' onClick={()=>{
      if(menu){
        isMenu(false)}
      }
    }>
    <article>
        <h3>3.13 Token mint</h3>
        <img alt='add' src='/images/mint.jpg' />
      </article>
    <article>
        <h3>3.14 Burn</h3>
        <img alt='add' src='/images/burn.jpg'/>
      </article>
      <PrevNext prev='/docs/jupiter' next='/docs/tips'/>
    </div>
  )
}

export default Others
