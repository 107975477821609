import React from 'react'
import { useOutletContext } from 'react-router-dom'
import PrevNext from '../components/PrevNext'

const Notis = () => {
  const {menu, isMenu} = useOutletContext()
  return (
    <div className='intro' onClick={()=>{
      if(menu){
        isMenu(false)}
      }
    }>
      <article>
        <h3>3.0 Notifications</h3>
        <p>
        Notifications are categorized into four descriptive sections: <br/><br/>1. Group and Transaction Type: Displays the group and type of transaction (e.g., buy, sell, transfer).
        </p>
        <img alt='add' src='/images/not1.jpg'/>
      </article>
      <article>
        <p>
        2. Group and Transaction Type: Displays the group and type of transaction (e.g., buy, sell, transfer).
        </p>
        <img alt='add' src='/images/not2.jpg'/>
      </article>
      <article>
        <p>
        3. Additional Information: Includes ticker symbol, contract address, and "Do Your Own Research" (DYOR) options.
        </p>
        <img alt='add' src='/images/not3.jpg'/>
      </article>
      <article>
        <p>
        4. Performance Metrics: Shows the win rate and involved wallets.
        </p>
        <img alt='add' src='/images/not4.jpg'/>
      </article>
      <article>
        <p>
        5. Buy Bot Integration: Easily trade tokens with a simple click using our built-in buy bot.
        </p>
        <img alt='add' src='/images/not5.jpg'/>
      </article>
      <PrevNext prev='/docs/filter' next='/docs/swaps'/>
    </div>
  )
}

export default Notis
