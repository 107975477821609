import React from 'react'

const Hero = () => {
  return (
    <div className='Hero' id='Home'>
      <section>
        <article>
        <h1>
           The Secret is to get in early 
        </h1>
        <p>See what profitable whales are bidding in one Click</p>
        <a href='https://t.me/Godeye_wallet_trackerBot'> <button>
            Start Godeye 
        </button></a>
        </article>
        <main>
            <img alt='hero' src='/images/hero3.png' />
        </main>
      </section>
    </div>
  )
}

export default Hero
