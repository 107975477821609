import React from 'react'
import { useOutletContext } from 'react-router-dom'
import PrevNext from '../components/PrevNext'
const Transfer = () => {
  const {menu, isMenu} = useOutletContext()
  return (
    <div className='intro' onClick={()=>{
      if(menu){
        isMenu(false)}
      }
    }>
      <article>
        <h3>2.7 Transfer wallet</h3>
        <p>
        You can move your wallet data to a new Telegram account if you lose your device or your telegram gets banned simply by using a “Transfer key” <br/><br/>There are two ways this can be done
        </p>
        <img alt='add' src='/images/trf1.jpg'/>
        <p>1. Import : Importing your wallet is essentially the process of adding an existing wallet data to a new telegram account or device. Simply send the transfer key of the account you want to import </p>
        <img alt='add' src='/images/trf2.jpg'/>
        <p>The old account will be erased and automatically transferred to the new telegram account or device <br/><br/>2 Export : Exporting a wallet is essentially creating a backup of your wallet data, it acts like a private key , allowing you to restore it on a different telegram account  or device using a transfer key. This is crucial for security and accessibility.</p>
        <img alt='add' src='/images/trf3.jpg'/>
        <p>
        Your transfer key can be imported to any telegram account , guaranteeing you full access to all your wallet data, and current plan.<br/><br/> Transfer key woks like a private key .Anyone who possesses your transfer key has the ability to access and control your wallet data. Protect your transfer key. 
        </p>
        </article>
      <article>
        <h3>2.7.1 Wrong transfer key</h3>
        <p>
        If you encounter this message, it indicates that you may have entered an incorrect transfer key for an account that doesn't exist. Please double-check the key and try again.
        </p>
        <img alt='add' src='/images/trf4.jpg'/>
        </article>
      <PrevNext prev='/docs/upgrade' next='/docs/download'/>
    </div>
  )
}

export default Transfer
