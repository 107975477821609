import React from 'react'
import { useOutletContext } from 'react-router-dom'
import PrevNext from '../components/PrevNext'
const Dadd = () => {
  const {menu, isMenu} = useOutletContext()
  return (
    <div className='intro' onClick={()=>{
      if(menu){
        isMenu(false)}
      }
    }>
      <article>
        <h3>2.4 How to delete wallets</h3>
        <p><strong>Method 1:</strong> Open the godeye bot, click on menu and select “/Delete”</p>
        <img alt='add' src='/images/del.jpg'/>
        <p>
        To delete a wallet, simply send the wallet address without including the name or group. The wallet will be successfully deleted.
        </p>
        <img alt='add' src='/images/del3.jpg'/>
      </article>
      <article>
        <h3>2.4.1 Deleting multiple wallets</h3>
        <p>To delete multiple wallets, click "/Delete." Send each wallet address on a new line, ensuring proper spacing in this format<br/><br/>“Wallet1 nickname group”<br/>“Wallet2 nickname group”</p>
        <img alt='add' src='/images/del2.jpg'/>
      </article>
      <article>
        <h3>2.4.1 Unsaved wallets</h3>
        <p>If you encounter this message while deleting , it indicates that the wallet you entered is not saved in your database. It's possible that you never owned this wallet.</p>
        <img alt='add' src='/images/del4.jpg'/>
      </article>
      <PrevNext prev='/docs/grouping-wallet' next='/docs/account'/>
    </div>
  )
}

export default Dadd
