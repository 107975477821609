import React from 'react'
import { useOutletContext } from 'react-router-dom'
import PrevNext from '../components/PrevNext'

const Filter = () => {
  const {menu, isMenu} = useOutletContext()
  return (
    <div className='intro' onClick={()=>{
      if(menu){
        isMenu(false)}
      }
    }>
      <article>
        <h3>2.9 Filter Notifications</h3>
        <p>
        🔔Customize your notifications. Choose which types of transactions you want to be notified about.
        </p>
        <img alt='add' src='/images/fil1.jpg'/>
        <p>To activate a particular transaction type, click /start ,select “filter” </p>
        <img alt='add' src='/images/fil2.jpg'/>
        <p>select a transaction type and choose "Activate" or "Deactivate " to enable or disable it. </p>
        <img alt='add' src='/images/fil3.jpg'/>
      </article>
      <PrevNext prev='/docs/download' next='/docs/notification-types'/>
    </div>
  )
}

export default Filter
