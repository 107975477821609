import React from 'react'
import { useOutletContext } from 'react-router-dom'
import PrevNext from '../components/PrevNext'

const Upg = () => {
  const {menu, isMenu} = useOutletContext()
  return (
    <div className='intro' onClick={()=>{
      if(menu){
        isMenu(false)}
      }
    }>
      <article>
        <h3>2.6 How to Upgrade</h3>
        <p>Please ensure you have sufficient funds in your Godeye wallet to upgrade. The required balance is: Phoenix: 0.2 SOL Valkyrie: 0.3 SOL Odin: 0.5 SOL Zeus: 1 SOL</p>
        <img alt='add' src='/images/price.jpg'/>
        <p style={{lineHeight: '26px'}}>
        📝 How to upgrade<br/><br/> To subscribe to Phoenix, please transfer a minimum of 0.21 SOL to your Godeye wallet. You can locate your Godeye wallet in the "My Accounts" menu. <br/>Once you've transferred the funds, navigate to the "Upgrade" section and select "Phoenix " plan. A fee of 0.2 SOL will be deducted from your account, and your wallet limit will be automatically increased.
        </p>
        <img alt='add' src='/images/upg1.jpg'/>
      </article>
      <article>
        <h3>2.6.1 How to Extend current plan</h3>
        <p>This menu is only visible to users with an active subscription.</p>
        <img alt='add' src='/images/upg2.jpg'/>
        <p style={{lineHeight: '26px'}}>
        click “extend” to add an extra 30 days or 1yr to your current plan <br/><br/> 🔷Plus 1 month : Your current plan will remain active, and you'll receive an additional 30-day period to enjoy its full benefits. <br/><br/> 🔷Plus 1 year : Your current plan will remain active, and you'll receive an additional 1year period to enjoy its full benefits.
        </p>
        <img alt='add' src='/images/upg3.jpg'/>
      </article>
      <article>
        <h3>2.6.2 Insufficient balance </h3>
        <p>If you see this message, it means you don't have enough funds in your wallet to upgrade. Please deposit the required amount for your desired plan and try again.</p>
        <img alt='add' src='/images/ins.jpg'/>
      </article>
      <PrevNext prev='/docs/account' next='/docs/transfer-account'/>
    </div>
  )
}

export default Upg
