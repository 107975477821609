import React from 'react'

const Features = () => {
  return (
    <div className='features' id='features'>
      <section>
        <header>
            <h1>Features</h1>
        </header>
        <article>
            <ul>
            <li>
                <img alt='speed' src='/images/speed.png' />
                <h3>Lightening fast alerts</h3>
                <p>Godeye wallet tracker provides lightning-fast alerts for all your wallet activity. This means you'll be the first to know about any transactions, transfers, or other significant events related to your Solana wallets. With Godeye, you'll never miss a beat.
                </p>
            </li>
            <li>
                <img alt='speed' src='/images/wallet.png' />
                <h3>High wallet limit</h3>
                <p>Godeye wallet tracker lets you seamlessly monitor all your wallets in one place. With our high wallet limit, you can track as many wallets as you need without any restrictions.
                </p>
            </li>
            <li>
                <img alt='speed' src='/images/chess.png' />
                <h3>Alpha wallets</h3>
                <p>Premium users gain exclusive access to our high-performance alpha wallets. These wallets have a proven track record of success, giving you a significant edge in the Solana ecosystem.</p>
            </li>
            </ul>
        </article>
      </section>
      <section>
        <header>
            <h1>Advanced Features</h1>
        </header>
        <article>
            <ul>
            <li>
                <img alt='speed' src='/images/group.png' />
                <h3>Group wallets</h3>
                <p>wallet grouping feature allows you to categorize and organize your Solana wallets into custom groups based on win rates. This makes it easier to manage and track your entire portfolio efficiently.</p>
            </li>
            <li>
                <img alt='speed' src='/images/download.png' />
                <h3>Download wallets</h3>
                <p>You can effortlessly create a backup of all your tracked wallets. This ensures your valuable wallet data is always safe and accessible, even in the event of telegram ban or device failure.</p>
            </li>
            <li>
                <img alt='speed' src='/images/transfer.png' />
                <h3>Transfer wallet</h3>
                <p>With Godeye's Transfer Wallet feature, you have the power to seamlessly move your entire wallet portfolio between different devices or platforms. Simply use your unique transfer key to import or export your wallets effortlessly.</p>
            </li>
            </ul>
            <ul>
            <li>
                <img alt='speed' src='/images/analyse.png' />
                <h3>Check winrate</h3>
                <p>Godeye allows you to check your wallet's winrate, providing a detailed analysis of its performance. Track profit and loss (P&L), 30-day buy history, and more.</p>
            </li>
            <li>
                <img alt='speed' src='/images/mag.png' />
                <h3>DYOR</h3>
                <p>Easily search for projects on Twitter, Rick, PumpFun, DEXs, and more, all within our intuitive platform </p>
            </li>
            <li>
                <img alt='speed' src='/images/list.png' />
                <h3>Transaction summary</h3>
                <p>Too lazy to read through hundreds of messages? No problem. you can quickly and easily download a detailed report of all your wallet activity like buys,sells and transfers within the past 6 hours.Stay informed and in control with Godeye's Transaction Summary feature.</p>
            </li>
            </ul>
        </article>
      </section>
    </div>
  )
}

export default Features
