import React from 'react'
import { useOutletContext } from 'react-router-dom'
import PrevNext from '../components/PrevNext'

const Jupi = () => {
  const {menu, isMenu} = useOutletContext()
  return (
    <div className='intro' onClick={()=>{
      if(menu){
        isMenu(false)}
      }
    }>
    <article>
        <h3>3.12 Jupiter DCA</h3>
        <img alt='add' src='/images/dca.jpg' />
      </article>
    <article>
        <h3>3.13 Jupiter Perps</h3>
        {/* <img alt='add' src='/images/sell.jpg' /> */}
      </article>
      <PrevNext prev='/docs/liquidity' next='/docs/others'/>
    </div>
  )
}

export default Jupi
