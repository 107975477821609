import React from 'react'
import { useOutletContext } from 'react-router-dom'
import PrevNext from '../components/PrevNext'

const Lp = () => {
  const {menu, isMenu} = useOutletContext()
  return (
    <div className='intro' onClick={()=>{
      if(menu){
        isMenu(false)}
      }
    }>
    <article>
        <h3>3.10 Add liquidity</h3>
        <img style={{width: '60%'}} alt='add' src='/images/addl.jpg' />
      </article>
    <article>
        <h3>3.11 Remove liquidity</h3>
        <img style={{width: '60%'}} alt='add' src='/images/rem.jpg'/>
      </article>
      <PrevNext prev='/docs/nfts' next='/docs/jupiter'/>
    </div>
  )
}

export default Lp
