import React from 'react'
import './Docs.css'
import { NavLink, Outlet } from 'react-router-dom'
import Nav from '../components/Nav'
import Navv from '../components/Navv'
import { useState, useEffect } from 'react'
import PrevNext from '../components/PrevNext'

const Docs = () => {
    const [mobile, setMobile] = useState(false)
    const [menu, setMenu] = useState(false)
    const media = window.matchMedia('(max-width:620px)').matches
    useEffect(()=>{
  setMobile(true)
    }, [media])

    function isMenu(sss){
        setMenu(sss)
        console.log('food')
    }
  return (
    <div className='docCon'>
    <div className='docsNav'><Navv menu={menu} isMenu={isMenu}/></div>
 <div className='docs'>
      <nav className={menu && 'openmenu'}>
      <div>
        <ul>
        <li>{mobile && <img alt='logo' src='/images/logo.png' style={{width: '100px'}} />}</li>
            <li>
                <NavLink to='/docs'>INTRODUCTION</NavLink>
            </li>
            <li>
            <p className='subHeader'>Basic features</p>
            <ul className='subnav'>
                <li><NavLink to='/docs/add-wallet' activeClassName='active'>Add wallets</NavLink></li>
                <li><NavLink to='/docs/add-multiple-wallet' activeClassName='active'>Add multiple wallets</NavLink></li>
                <li><NavLink to='/docs/grouping-wallet' activeClassName='active'>Grouping wallets</NavLink></li>
                <li><NavLink to='/docs/deleting-wallet' activeClassName='active'>Deleting wallets</NavLink></li>
                <li><NavLink to='/docs/account' activeClassName='active'>My Acount</NavLink></li>
                <li><NavLink to='/docs/upgrade' activeClassName='active'>Upgrade</NavLink></li>
                <li><NavLink to='/docs/transfer-account' activeClassName='active'>Transfer Account</NavLink></li>
                <li><NavLink to='/docs/download' activeClassName='active'>Download wallet</NavLink></li>
                <li><NavLink to='/docs/filter' activeClassName='active'>Filter</NavLink></li>
            </ul>
            </li>
            <li>
            <p className='subHeader'>Notifications</p>
            <ul className='subnav'>
                <li><NavLink to='/docs/notification-types' activeClassName='active'>Types</NavLink></li>
                <li><NavLink to='/docs/swaps' activeClassName='active'>Swaps</NavLink></li>
                <li><NavLink to='/docs/transfers' activeClassName='active'>Transfer</NavLink></li>
                <li><NavLink to='/docs/nfts' activeClassName='active'>NFTs & cNFTs</NavLink></li>
                <li><NavLink to='/docs/liquidity' activeClassName='active'>Liquidity</NavLink></li>
                <li><NavLink to='/docs/jupiter' activeClassName='active'>Jupiter</NavLink></li>
                <li><NavLink to='/docs/others' activeClassName='active'>Others</NavLink></li>
            </ul>
                
            </li>
            <li>
                <NavLink to='/docs/tips' activeClassName='active'>Tips</NavLink>
            </li>
            {/* <li>
                <NavLink to='/docs/faqs' activeClassName='active'>FAQs</NavLink>
            </li> */}
        </ul>
        </div>
      </nav>
      <section className='right'>
        <Outlet context={{menu, isMenu}}/>
      </section>
    </div>
    </div>
  )
}

export default Docs
