import React from 'react'

const Use = () => {
  return (
    <div className='use' id='use'>
      <section>
      <header>
        <h1>How to use Godeye Bot ?</h1>
      </header>
      <main>
        <button><a href='https://www.youtube.com/@Godeye_bots'>Watch Tutorials</a></button>
        <button><a href='/docs'>Read Docs</a></button>
      </main>
      </section>
    </div>
  )
}

export default Use
