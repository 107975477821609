import React from 'react'
import { useOutletContext } from 'react-router-dom'
import PrevNext from '../components/PrevNext'

const Download = () => {
  const {menu, isMenu} = useOutletContext()
  return (
    <div className='intro' onClick={()=>{
      if(menu){
        isMenu(false)}
      }
    }>
      <article>
        <h3>2.8 Download</h3>
        <p>
        You can effortlessly create a backup of all your tracked wallets. This ensures your valuable wallet data is always safe and accessible, even in the event of telegram ban or device failure.
        </p>
        <img alt='add' src='/images/dow1.jpg'/>
        <p>Click /start , select “download wallet” to generate a pdf containing all your wallets</p>
        <img alt='add' src='/images/dow2.jpg'/>

      </article>
      <PrevNext prev='/docs/transfer-account' next='/docs/filter'/>
    </div>
  )
}

export default Download
