import React from 'react'
import { useOutletContext } from 'react-router-dom'
import PrevNext from '../components/PrevNext'

const Intro = () => {
  const {menu, isMenu} = useOutletContext()
  return (
    <div className='intro' onClick={()=>{
      if(menu){
        isMenu(false)}
      }
    }>
      <h1>1. INTRODUCTION</h1>
      <article>
        <h3>1.1 What is Godeye ?</h3>
        <p>
        Godeye is an advanced Solana wallet tracking bot designed to empower users with real-time insights, comprehensive features, and unparalleled performance.
        </p>
        <img alt='godeye' src='/images/logo.png'/>
      </article>
      <article>
        <h3>1.2 What is wallet tracking ?</h3>
        <p>
        Wallet tracking refers to the process of monitoring and managing your digital wallet activity. This typically involves tracking transactions, balances, and other relevant data associated with your wallet.
        A wallet tracker is a tool that automates this process, providing users with real-time updates and insights into their wallet activity.
        </p>
      </article>
      <article>
        <h3>1.3 How to find wallets</h3>
        <p>
        <strong> 1.3.1 Method 1 :</strong> Explore token holders on Solscan. Search for a token, analyze its top holders, and identify wallets with interesting activity. Copy these wallet addresses and add them to Godeye for real-time tracking.
        </p>
        <p>
        <strong> 1.3.2 Method 2 :</strong> Follow influencers and communities like Godeye Lounge for discussions on whale activity. Many influencers will share their wallet addresses. Keep an eye on their token holdings and compare their supply to the top holders listed on Solscan.
        </p>
      </article>
      <article>
        <h3>1.4 Benefits of wallet tracking</h3>
        <ol>
        <li>Early entry opportunities: Identify potential investment opportunities before they become widely known.</li>
        <li>Informed decision-making: Data-driven investment strategies and trend identification.</li>
        <li>Peace of mind: Reduced stress and proactive management of crypto investments.</li>
       </ol>
      </article>
      <article>
        <h3>1.5 How to find set up bots</h3>
        <p>
        To easily find us , search “Godeye_wallet_trackerBot” on telegram or <a href='https://t.me/godeye_wallet_tracker'>Start Godeye</a>
        </p>
      </article>
      <PrevNext prev='' next='/docs/add-wallet'/>
    </div>
  )
}

export default Intro
