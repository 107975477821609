import React from 'react'
import { useOutletContext } from 'react-router-dom'
import PrevNext from '../components/PrevNext'

const Swaps = () => {
  const {menu, isMenu} = useOutletContext()
  return (
    <div className='intro' onClick={()=>{
      if(menu){
        isMenu(false)}
      }
    }>
    <article>
        <h3>3.1 Buy</h3>
        <img alt='add' src='/images/buy.jpg' />
      </article>
    <article>
        <h3>3.2 Sell</h3>
        <img alt='add' src='/images/sell.jpg' />
      </article>
    <article>
        <h3>3.3 Swap</h3>
        <img alt='add' src='/images/swap.jpg' />
      </article>
      <PrevNext prev='/docs/notification-types' next='/docs/transfers'/>
    </div>
  )
}

export default Swaps
