import React from 'react'

const Lounge = () => {
  return (
    <div className='lounge'>
      <section>
      <a href='https://t.me/Godeye_olympus'> <button>
            Join community
        </button></a>
        <a href='https://t.me/Godeye_news_channel'> <button>
            Join News channel
        </button></a>
      </section>
    </div>
  )
}

export default Lounge
