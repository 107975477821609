import React from 'react'
import { useOutletContext } from 'react-router-dom'
import PrevNext from '../components/PrevNext'

const Addm = () => {
  const {menu, isMenu} = useOutletContext()
  return (
    <div className='intro' onClick={()=>{
      if(menu){
        isMenu(false)}
      }
    }>
      <article>
        <h3>2.2 How to add multiple wallets</h3>
        <p>
        To add multiple wallets, Click /add , send each wallet on a new line in this format <br/><br/>"Wallet1 nickname group”<br/>“Wallet2 nickname group”<br/><br/>Ensure proper spacing and formatting to avoid errors.
        </p>
        <img alt='add' src='/images/add3.jpg'/>
      </article>
      <PrevNext prev='/docs/add-wallet' next='/docs/grouping-wallet'/>
    </div>
  )
}

export default Addm
