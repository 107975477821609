import './App.css';
// import CommLounge from './components/CommLounge';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Docs from './pages/Docs';
import Home from './pages/Home';
import Intro from './pages/Intro';
import Func from './pages/Func';
import Notis from './pages/Notis';
import Tips from './pages/Tips';
import Faqs from './pages/Faqs';
import Addm from './pages/addm';
import AddG from './pages/AddG';
import Dadd from './pages/Dadd';
import Acct from './pages/Acct';
import Upg from './pages/Upg';
import Transfer from './pages/Transfer';
import Download from './pages/Download';
import Filter from './pages/Filter';
import Txtypes from './components/Txtypes';
import Swaps from './pages/Swaps';
import Trf from './pages/Trf';
import Nfts from './pages/Nfts';
import Lp from './pages/Lp';
import Jupi from './pages/Jupi';
import Others from './pages/Others';

function App() {
  return (
    <BrowserRouter>
    <div>
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/docs' element={<Docs/>}>
          <Route index element={<Intro/>} />
          <Route path='/docs/add-wallet' element={<Func/>} />
          <Route path='/docs/add-multiple-wallet' element={<Addm/>}/>
          <Route path='/docs/grouping-wallet' element={<AddG/>}/>
          <Route path='/docs/deleting-wallet' element={<Dadd/>}/>
          <Route path='/docs/account' element={<Acct/>}/>
          <Route path='/docs/upgrade' element={<Upg/>}/>
          <Route path='/docs/transfer-account' element={<Transfer/>}/>
          <Route path='/docs/download' element={<Download/>}/>
          <Route path='/docs/filter' element={<Filter/>}/>
          <Route path='/docs/notification-types' element={<Notis/>} />
          <Route path='/docs/swaps' element={<Swaps/>} />
          <Route path='/docs/transfers' element={<Trf/>} />
          <Route path='/docs/nfts' element={<Nfts/>} />
          <Route path='/docs/liquidity' element={<Lp/>} />
          <Route path='/docs/jupiter' element={<Jupi/>} />
          <Route path='/docs/others' element={<Others/>} />
          <Route path='/docs/tips' element={<Tips/>} />
          <Route path='/docs/faqs' element={<Faqs/>} />
          </Route>
        </Routes>
    </div>
    </BrowserRouter>
  );
}

export default App;
