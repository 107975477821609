import React from 'react'
import { useState, useEffect } from 'react'
import './comp.css'


const Navv = ({menu, isMenu}) => {
    const [mobile, setMobile] = useState(false)
    const media = window.matchMedia('(max-width:620px)').matches
    useEffect(()=>{
  setMobile(true)
    }, [media])
  return (
    <div className='nav'>
      <nav style={{height: mobile && '70px'}}>
      <a href='/'><img alt='logo' src='/images/logo.png' style={{height: mobile && '100%'}}/></a>
        <ul>
            <li className='navLink'><a href='/#home'>Home</a></li>
            <li className='navLink'><a href='/#features'>Features</a></li>
            <li className='navLink'><a href='/docs'>Tutorials</a></li>
            <li className='navLink'><a href='/#pricing'>Pricing</a></li>
      
           {mobile ? <img alt='logo' src='/images/menu.png' id='menu' style={{width: '30px'}}  onClick={()=>{
        isMenu(!menu)
      }} /> : <li>  <a href='https://t.me/Godeye_wallet_trackerBot'> <button>
            Start Godeye 
        </button></a></li>} 
        </ul>
      </nav>
    </div>
  )
}

export default Navv
