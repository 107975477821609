import React from 'react'

const Footer = () => {
  return (
    <div className='foot'>
      <footer>
        <img alt='logo2' className='logo2' src='/images/logo2.png' />
        <main className='socials'>
            <a href='https://t.me/Godeye_olympus'><img alt='telegram' src='/images/telegram2.png'/>Telegram</a>
            <a href='https://x.com/Godeye_Network'><img alt='twitter' src='/images/x2.png'/>Twitter</a>
            <a href='https://www.youtube.com/@Godeye_bots'><img alt='youtube' src='/images/yt2.png'/>Youtube</a>
        </main>
        <small>Copyrights reserved @ 2024</small>
      </footer>
    </div>
  )
}

export default Footer
